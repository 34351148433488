export default {
  // Base url path
  // baseUrl: 'http://localhost:3000/nodeapp',
  baseUrl: 'https://mycaretrackhealth.com/erb_api',
  // baseUrl: 'http://192.168.150.62:5000/nodeapp',
  // baseUrl: 'http://192.168.150.18:3000/nodeapp', // Andrei Maquina
  // baseUrl: 'http://localhost:3000/erb_api', // Sebastian Maquina
  // baseUrl: 'http://10.30.3.249:3000/erv_api', // Sebastian Maquina

  // Endpoints
  loginEndpoint: '/api/v1/auth/login',
  verifyAuthyCodeEndpoint: '/api/v1/auth/verify',
  resetPassword: '/api/v1/auth/change-password',
  recoveryPassword: '/api/v1/auth/recover-password',
  forgotPassword: '/api/v1/auth/forgot-password',

  getSharedPatients: '/api/v1/share/get-shared-patients',

  getSharedPatientsCharts: '/api/v1/share/get-shared-patients-graphs',
  getSharedPatientsChartsStaff: '/api/v1/share/get-shared-patients-graphs-staff',
  getSharedPatientsChartsStage: '/api/v1/share/get-shared-patients-graphs-stage',
  getSharedPatientsChartsActions: '/api/v1/share/get-shared-patients-graphs-actions',

  getFAQ: '/api/v1/share/get-faq',
  getFAQCategories: '/api/v1/share/get-faq-categories',

  getSharedPatientsExcel: '/api/v1/share/get-shared-patients-excel',
  addSharedPatientAction: '/api/v1/share/add-shared-patient-action',
  notifyShare: '/api/v1/share/notify-share',
  addComment: '/api/v1/share/add-comment',
  getSharedPatient: '/api/v1/share/get-shared-patient',
  getSharedPatientComments: '/api/v1/share/get-shared-patient-comments',
  getSharedPatientLog: '/api/v1/share/get-shared-patient-changelog',
  reasignSharedPatient: '/api/v1/share/reassign-shared-patient',
  deleteShare: '/api/v1/share/delete-shared-patient',
  updateSharedPatientComments: '/api/v1/share/update-shared-patient-comments',
  getAllElementCatalog: '/api/v1/share/get-simple-catalog',
  getCallCalendar: '/api/v1/share/get-shcheduled-calls-calendar',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
